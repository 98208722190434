<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Which of the following molecules are polar? Also, does a pure sample of the compound have
        hydrogen bonds? <b>Hint.</b> Draw the Lewis dot structures.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Molecule</th>
            <th style="font-size: 15px">Polar</th>
            <th style="font-size: 15px">Hydrogen Bonds</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-latex content="a) XeO2F2" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputPolarA" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputHBondA" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="b) HOOH" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputPolarB" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputHBondB" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="c) CH2Cl2" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputPolarC" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputHBondC" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="d) H2NNH2" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputPolarD" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputHBondD" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="e) SF6" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputPolarE" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.inputHBondE" :disabled="!allowEditing">
                <v-radio key="yes" value="yes" label="Yes" />
                <v-radio key="no" value="no" label="No" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1210S4_Q8',
  components: {ChemicalLatex, STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        inputPolarA: null,
        inputPolarB: null,
        inputPolarC: null,
        inputPolarD: null,
        inputPolarE: null,
        inputHBondA: null,
        inputHBondB: null,
        inputHBondC: null,
        inputHBondD: null,
        inputHBondE: null,
      },
    };
  },
};
</script>
